import { ShiftResource } from '@/types/event';
import type { InvitePartner } from '@/types/invite';
import type { RoomBookingResource } from '@/types/room-booking';
import { useToast } from 'vue-toastification';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';

export const acceptInvite = async (invite: {
  id: number;
  recurring_dates: string[];
  project_leader_id: number | null;
  event_type_id: number | null;
}) => {
  const result =
    (invite.recurring_dates?.length ?? 0 > 0)
      ? await useRecurringModal().recurringModal(
          null,
          'Accept Invite',
          'Join all recurrences of this event or just this one?'
        )
      : false;
  if (result === 'cancel') return false;

  await axios
    .post(`/api/invites/${invite.id}/accept`, {
      global: result === 'all',
      project_leader_id: invite.project_leader_id,
      event_type_id: invite.event_type_id,
    })
    .catch((error) => {
      const toast = useToast();
      if (error.response.data.message) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Could not accept invite');
      }
      return false;
    });
  const toast = useToast();
  toast.success('Accepted');
  return true;
};

export const declineInvite = async (invite: {
  id: number;
  recurring_dates: string[];
  event: {
    name: string;
  };
}) => {
  const certain = await useCertaintyModal().assertCertain(
    'Decline Invite',
    `Are you sure you want to decline ${invite.event.name}?`
  );
  if (!certain) return false;

  const result =
    invite.recurring_dates.length > 0
      ? await useRecurringModal().recurringModal(
          '',
          'Decline All Recurrences of event',
          `Do you want to decline all ${invite.recurring_dates.length} recurrences of this event, or just this one?`
        )
      : false;
  if (result === 'cancel') return false;

  await axios
    .post(`/api/invites/${invite.id}/decline`, {
      global: result === 'all',
    })
    .catch((error) => {
      const toast = useToast();
      if (error.response.data.message) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Could not decline invite');
      }
      return false;
    });
  const toast = useToast();
  toast.success('Declined');
  return true;
};

export const eventInvite = async (eventId: number, data: object) => {
  return axios.post<{
    status: string;
    accepted: boolean;
    id: number | null;
  }>('/api/invites', {
    event_id: eventId,
    ...data,
  });
};

export const getInvitePartners = async (inviteId: number) => {
  const url = `/api/invites/${inviteId}/partners`;
  return axios.get<InvitePartner>(url);
};

export const getInviteRoomBookings = async (inviteId: number) => {
  const url = `/api/invites/${inviteId}/room-booking`;
  return axios.get<RoomBookingResource[]>(url);
};

export const postDeclineInvite = async (inviteId: number, global = false) => {
  return axios.post<string>(`/api/invites/${inviteId}/decline`, {
    is_global: global,
  });
};

export const getShiftForInvite = async (inviteId: number) => {
  const url = `/api/invites/${inviteId}/owner-shifts`;
  return axios.get<ShiftResource[]>(url);
};
