import type { ShiftResource } from '@/types/event';
import { FestivalMinimalResource } from '@/types/festival';
import type { InviteResource } from '@/types/invite';

const BASE_URL = '/api/dashboard';

export const getDashboardInvites = async () => axios.get<InviteResource[]>(BASE_URL + '/invites');

export const getDashboardShifts = async () => axios.get<ShiftResource[]>(BASE_URL + '/shifts');

export const getDashboardShiftsForSale = async () => axios.get<ShiftResource[]>(BASE_URL + '/shifts/for_sale');

export const getDashboardShiftsRequested = async () => axios.get<ShiftResource[]>(BASE_URL + '/shifts/requested');

export const getDashboardShiftsDeclined = async () => axios.get<ShiftResource[]>(BASE_URL + '/shifts/declined');

export const getDashboard = async (start: string, end: string, withAssignments: boolean, withShiftsForSale = false) => {
  return axios.get(BASE_URL, {
    params: {
      start,
      end,
      with_assignments: withAssignments,
      with_shifts_for_sale: withShiftsForSale,
    },
  });
};

export const getPersonalFestivals = async () => axios.get<FestivalMinimalResource[]>(BASE_URL + '/festivals');
