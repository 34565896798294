import { getRoute } from '@/util/route';

export const usePersonalMenu = (settingsAction: () => void, missingPhone = false) => [
  {
    hash: '',
    key: 'personal',
    title: 'Dashboard',
    icon: 'fa-home fa-regular',
    route: getRoute('events'),
  },
  {
    hash: '',
    key: 'personal_calendar',
    title: 'Calendar',
    icon: 'fa-calendar fa-regular',
    route: getRoute('events') + '#calendar',
  },
  {
    hash: '',
    key: 'personal_tasks',
    title: 'Tasks',
    icon: 'fa-tasks fa-regular',
    route: getRoute('userTasks'),
  },
  {
    hash: '',
    key: 'personal_settings',
    title: 'Settings',
    icon: 'fa-cog fa-regular',
    missingItem: missingPhone,
    missingTooltip: 'Hallo World',
    action: () => {
      settingsAction();
    },
  },
];
