import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { getEventResources } from '@/services/api-event-resources';
import { IEventResource } from '@/types/event-resource';

export const useEventResourcesStore = defineStore('event-resources', () => {
  const list = ref<Map<number, IEventResource[]>>(new Map());
  const loading = ref<boolean>(false);

  const listenForBroadcast = (eventId: number) => {
    Echo.channel(`on.Event.${eventId}`).listen('.resources.updated', async () => {
      fetch(eventId);
    });
  };

  const fetch = async (eventId: number) => {
    loading.value = true;
    const { data } = await getEventResources(eventId);
    if (!list.value.has(eventId)) {
      listenForBroadcast(eventId);
    }

    list.value.set(eventId, data);

    loading.value = false;
    return data;
  };

  return {
    list,
    loading,
    fetch,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEventResourcesStore, import.meta.hot));
}
