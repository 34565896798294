<script lang="ts" setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { formatStampAsDate, highlightDatesForKeys } from '@/util/timeFunctions';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { focusOnField, slotEmpty } from '@/util/globals';
import '@vuepic/vue-datepicker/dist/main.css';
import { inputInTable, startKey } from '@/provide/keys';

type PropType = {
  modelValue?:
    | string
    | number
    | string[]
    | Date
    | Date[]
    | number[]
    | {
        hours: string | number;
        minutes: string | number;
        seconds?: string | number | undefined;
      };
  canEdit?: boolean;
  earliestDate?: string | Date | undefined;
  label?: string;
  labelTitle?: string;
  disabledLabelTitle?: string;
  labelPlacement?: string;
  required?: boolean;
  isHidden?: boolean;
  inputClass?: string;
  inline?: boolean;
  monthPicker?: boolean;
  flow?: ('month' | 'year' | 'calendar' | 'time' | 'minutes' | 'hours' | 'seconds')[];
  multiDates?: boolean;
  range?: boolean;
  maxDate?: string | Date | undefined;
  startDate?: string | Date | undefined;
  partialRange?: boolean;
  setFocus?: boolean;
  withIcon?: boolean;
  alwaysShowClear?: boolean;
  highlightToday?: boolean;
  disabledDates?: string[];
  highlightDays?: string[];
  inTableStyle?: boolean;
};

const props = withDefaults(defineProps<PropType>(), {
  modelValue: undefined,
  canEdit: true,
  earliestDate: undefined,
  label: '',
  labelTitle: null,
  disabledLabelTitle: null,
  labelPlacement: 'top',
  required: false,
  isHidden: false,
  inputClass: '',
  inline: false,
  monthPicker: false,
  multiDates: false,
  range: false,
  alwaysShowClear: true,
  maxDate: undefined,
  startDate: undefined,
  partialRange: true,
  withIcon: true,
  setFocus: false,
  highlightToday: false,
  disabledDates: () => [],
  highlightDays: () => [],
  flow: () => [],
  inTableStyle: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', val: string[] | string): void;
  (event: 'viewing:month', val: string): void;
  (event: 'viewing:year', val: string): void;
  (event: 'clear'): void;
  (event: 'closed'): void;
}>();
const inFocus = ref(false);

const inTable = inject(inputInTable, props.inTableStyle);

const onUpdate = (e: string | (string | null | undefined)[] | null | undefined) => {
  inFocus.value = false;
  if (props.multiDates) {
    if (!e) {
      emit('update:modelValue', []);
      return;
    }

    const t = Array.isArray(e) ? e.map((date: string | null | undefined) => formatStampAsDate(date)) : '';
    emit('update:modelValue', t);
  } else if (props.range) {
    if (!Array.isArray(e)) return;
    emit('update:modelValue', [formatStampAsDate(e[0]), formatStampAsDate(e[1])]);
  } else {
    if (Array.isArray(e)) return;
    emit('update:modelValue', formatStampAsDate(e));
  }
};
//TODO: why do we need this ?
// const justToMakeItImport = Datepicker;

const pos = computed(() => {
  switch (props.labelPlacement.toLowerCase()) {
    case 'left': {
      return '';
    }
    default: {
      return 'flex-col';
    }
  }
});

const datepicker = ref(null);
const wrapper = ref<HTMLElement | null>(null);

watch(
  () => props.modelValue,
  () =>
    handleMonthYear({
      month: new Date(String(props.modelValue)).getMonth(),
      year: new Date(String(props.modelValue)).getFullYear(),
    })
);

const viewMonth = ref<string | number>(new Date(String(props.modelValue)).getMonth());
const viewYear = ref<string | number>(new Date(String(props.modelValue)).getFullYear());

// For multiCalendars, instance will be the index of the calendar where the value is changed
const handleMonthYear: (arg0: { month: string | number; year: string | number }) => void = ({ month, year }) => {
  viewMonth.value = month;
  viewYear.value = year;
  emit('viewing:year', year);
  emit('viewing:month', month);
};

const overflowing = computed(() => {
  if (!wrapper.value) return false;
  const box = wrapper.value.getBoundingClientRect();

  return box.right + 150 > window.innerWidth;
});

onMounted(async () => {
  if (props.setFocus) {
    focusOnField(datepicker.value, 'openMenu');
  }
});
const clearDatePicker = () => {
  if (props.required) return;
  emit('clear');
  emit('update:modelValue', null);
};

const startFromKey = inject(startKey, null);
const getStartDate = computed(() => {
  if (props.startDate) return props.startDate;
  return startFromKey;
});

const customPosition = (el: HTMLElement) => {
  const box = el.getBoundingClientRect();
  let left: number;
  let top: number;

  const wrapperBox = wrapper.value?.getBoundingClientRect();

  if (box.left + 300 > window.innerWidth) {
    const diff = box.left + box.width + 300 - window.innerWidth;
    left = window.innerWidth - diff - 100;
  } else {
    left = box.left + box.width / 2 - 150;
  }

  if (wrapperBox) {
    if (wrapperBox.bottom + box.height + 300 > window.innerHeight) {
      top = wrapperBox.top - box.height - 10;
    } else {
      top = wrapperBox.bottom + 10;
    }
  }

  return {
    top: `${top}px`,
    left: `${left}px`,
  };
};
</script>

<template>
  <div
    ref="wrapper"
    class="flex"
    :class="[pos]">
    <InputLabel
      :label="label"
      :title="canEdit || !disabledLabelTitle ? labelTitle : disabledLabelTitle"
      :mandatory-text="required ? `${label} is required` : null" />

    <VueDatePicker
      v-bind="$attrs"
      ref="datepicker"
      :model-value="modelValue"
      :auto-apply="true"
      :teleport="true"
      :enable-time-picker="false"
      :month-change-on-scroll="false"
      :alt-position="overflowing ? customPosition : undefined"
      :readonly="!canEdit"
      :partial-flow="true"
      :disabled="!canEdit"
      :min-date="earliestDate"
      :required="required"
      :flow="flow"
      :inline="inline"
      :clearable="!required && (inFocus || alwaysShowClear)"
      :hide-input-icon="!withIcon"
      :multi-dates="multiDates"
      :month-picker="monthPicker"
      month-name-format="long"
      :day-names="['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']"
      :format="formatStampAsDate"
      :ui="{
        input: `${inputClass}  ${inTable ? 'input-field-table' : 'input-field'} ${isHidden ? 'datepicker-hidden' : ''} ${canEdit ? 'dp_input_can_edit' : 'dp_input_disabled'}`,
        menu: `menu-class ${
          highlightToday && viewMonth === new Date().getMonth() && viewYear === new Date().getFullYear()
            ? 'highlight-today'
            : ''
        } `,
        calendarCell: `dp-custom-cell`,
      }"
      :highlight="highlightDays.length > 0 ? highlightDays : highlightDatesForKeys()"
      :hide-navigation="['month', 'year']"
      :range="range"
      :auto-position="true"
      :max-date="maxDate"
      :start-date="getStartDate"
      :disabled-dates="disabledDates"
      @focus="inFocus = true"
      @blur="inFocus = false"
      @cleared="clearDatePicker"
      @closed="[emit('closed'), (inFocus = false)]"
      @update-month-year="handleMonthYear"
      @update:model-value="onUpdate">
      <template
        v-if="!slotEmpty($slots, 'override-input')"
        #dp-input>
        <slot name="override-input" />
      </template>
      <template #input-icon>
        <div
          :class="inFocus ? ' text ' : ' text-soft '"
          class="flex w-[35px] items-center justify-center">
          <i class="fa fa-fw fa-calendar fa-regular"></i>
        </div>
      </template>
      <template
        v-if="inFocus || alwaysShowClear"
        #clear-icon>
        <div class="flex w-[35px] items-center justify-center text-soft hover:text">
          <i
            class="fa fa-fw fa-times"
            @click.stop="clearDatePicker"></i>
        </div>
      </template>
      <template #calendar-header="{ index, day }">
        <span :class="index === new Date().getDay() - 1 ? 'dp-custom-today-header' : ''">{{ day }}</span>
      </template>

      <!-- LEGACY -->
      <template
        v-if="!slotEmpty($slots, 'trigger')"
        #trigger>
        <slot name="trigger" />
      </template>
    </VueDatePicker>
  </div>
</template>

<style>
.dp__theme_light {
  --dp-background-color: hsl(var(--color-background-input));
  --dp-text-color: hsl(var(--color-text));
  --dp-border-color: hsl(var(--color-border));
  --dp-hover-color: #f3f3f3;
  --dp-border-color-hover: var(--color-border-input-hover);
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #1976d2;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-menu-border-color: theme('borderColor.DEFAULT');
  --dp-disabled-color: transparent;
  --dp-scroll-bar-background: theme('backgroundColor.DEFAULT');
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-border-radius: theme('borderRadius.DEFAULT');
  --dp-range-between-dates-text-color: theme('textColor.DEFAULT');
}

.dp__input {
  height: 42px;
  font-size: 14px;
  padding-right: 10px;
}

.dp__input_focus {
  border-color: var(--color-border-input-focus);
}

.dp__input.dp__disabled:hover {
  border-color: hsl(var(--color-border));
}

.input-field-table {
  border: none;
  outline: 1px solid transparent;
  outline-offset: 1px;
  border-radius: 0;
  background-color: transparent;
}

.input-field-table:hover {
  border: none;
  outline: 1px solid var(--color-border-input-hover);
  outline-offset: 0;
  border-radius: 0;
}

.dp__input_focus.input-field-table {
  border: none;
  outline: 1px solid var(--color-border-input-hover);
  outline-offset: -1px;
  border-radius: 0;
}

/* .input-field {
  height: 40px;
  background-color: green !important;
  @apply border hover:border-[--color-border-input-hover];
}

*/

.datepicker-hidden {
  --dp-border-color: none;
  --dp-background-color: transparent;
}

.dp_input_can_edit.datepicker-hidden:hover {
  --dp-border-color: hsl(var(--color-border));
}

.dp_input_disabled.datepicker-hidden {
  --dp-background-color: transparent;
  background-color: hsl(var(--dp-background-color)) !important;
}

.menu-class {
  @apply rounded p-[12px];
}

.dp-custom-cell {
  @apply rounded border-none text-sm;
}

.highlight-today .dp-custom-today-header,
.dp__today:hover {
  background-color: hsl(var(--color-highlight-hover)) !important;
}

.dp__cell_offset {
  @apply text-soft;
}

.dp__date_hover_end:hover,
.dp__date_hover_start:hover,
.dp__overlay_cell:hover,
.dp__date_hover:hover,
.dp__inner_nav:hover {
  background-color: hsl(var(--color-highlight));
  color: hsl(var(--blue-100));
}

.dp__range_end,
.dp__range_start,
.dp__active_date {
  background-color: hsl(var(--color-highlight));
  color: hsl(var(--blue-100));
}

.dp__range_end:hover,
.dp__range_start:hover,
.dp__active_date:hover {
  background-color: hsl(var(--color-highlight-hover));
  color: hsl(var(--blue-100));
}

.dp__range_between {
  @apply bg;
}

.dp__month_year_row {
  @apply h-[unset] p-[0] pt-[8px];
}

.dp__month_year_wrap .dp__month_year_wrap {
  @apply mx-auto max-w-fit gap-[12px];
}

.dp__month_year_wrap .dp__month_year_wrap > * {
  width: unset;
}

.dp__inner_nav svg {
  @apply aspect-1 w-[16px];
}

.dp__month_year_select {
  @apply h-[unset] max-w-fit rounded-none border-b border-b-transparent !pb-[0] uppercase font-semibold;
}

.dp__month_year_select:hover {
  @apply border-b bg-transparent text-highlight;
}

.dp__calendar {
  @apply grid gap-[4px];
}

.dp__calendar_header_separator {
  display: none;
}

.dp__calendar_header {
  @apply mb-[0px] mt-[4px] gap-[4px] pb-[0];
}

.dp__calendar_header .dp__calendar_header_item {
  @apply h-auto font-body text-sm;
  font-weight: normal;
}

.dp__calendar_row {
  @apply m-[0] gap-[4px];
}

.dp__overlay_cell_active {
  @apply bg-highlight text-inverted;
}

.dp__cell_disabled {
  color: hsl(var(--color-text-disabled)) !important;
}

.dp__input_icon {
  color: hsl(var(--color-text-soft)) !important;
}
</style>
