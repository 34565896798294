import { eventMembersKey } from '@/provide/keys';
import { getEventInvites, getEventRooms } from '@/services/api-event';
import { getEventGroups } from '@/services/api-event-group';
import { getUserInvites } from '@/services/api-user-invites';
import { EventInviteResource, EventRoom } from '@/types/event';
import { EventGroupResource } from '@/types/event-group';
import { UserMinimalResource } from '@/types/user';
import { UserInviteResource } from '@/types/user-invite';
import { computed, provide, ref } from 'vue';

export const useEventMembers = (eventId: number, shouldProvide = true) => {
  const loading = ref(false);
  const loaded = ref(false);
  const eventGroups = ref<EventGroupResource[]>([]);
  const userInvites = ref<UserInviteResource[]>([]);
  const invites = ref<EventInviteResource | null>(null);

  const eventRooms = ref<EventRoom[]>([]);

  const allInvitesOfGroups = computed(() => invites.value?.groups ?? []);
  const acceptedInvitesOfGroups = computed(() => invites.value?.groups.filter((i) => i.accepted && !i.declined) ?? []);
  const pendingInvitesOfGroups = computed(() => invites.value?.groups.filter((i) => !i.accepted && !i.declined) ?? []);
  const declinedInvitesOfGroups = computed(() => invites.value?.groups.filter((i) => !i.accepted && i.declined) ?? []);

  const eventGroupHasUser = (userId: number) => {
    return eventGroups.value.some((g) => g.invited_members.some((u) => u.id === userId));
  };
  const allInvitesOfUsers = computed(
    () => invites.value?.users.filter((user) => !eventGroupHasUser(user.invitable_id)) ?? []
  );
  const acceptedInvitesOfUsers = computed(() => invites.value?.users.filter((i) => i.accepted && !i.declined) ?? []);
  const pendingInvitesOfUsers = computed(() => invites.value?.users.filter((i) => !i.accepted && !i.declined) ?? []);
  const declinedInvitesOfUsers = computed(() => invites.value?.users.filter((i) => !i.accepted && i.declined) ?? []);

  const acceptedInvitesOfFestivalSections = computed(
    () => invites.value?.festival_sections.filter((i) => i.accepted && !i.declined) ?? []
  );
  const pendingInvitesOfFestivalSections = computed(
    () => invites.value?.festival_sections.filter((i) => !i.accepted && !i.declined) ?? []
  );
  const declinedInvitesOfFestivalSections = computed(
    () => invites.value?.festival_sections.filter((i) => !i.accepted && i.declined) ?? []
  );

  const allMembers = computed(() => {
    const array: UserMinimalResource[] = [];
    acceptedInvitesOfUsers.value.forEach((i) => {
      array.push(i.invitable);
    });
    const groupMembers = acceptedInvitesOfGroups.value
      .map((i) => i.invitable.invited_members.filter((m) => m.isAccepted))
      .flat();
    const subGroupMembers = acceptedInvitesOfGroups.value
      .map((i) => i.invitable.invited_subgroups)
      .flat()
      .map((g) => g.invited_members.filter((m) => m.isAccepted))
      .flat();

    // add group members to array
    groupMembers.forEach((m) => {
      if (!array.some((u) => u.id === m.id)) {
        array.push(m);
      }
    });

    // add subgroup members to array
    subGroupMembers.forEach((m) => {
      if (!array.some((u) => u.id === m.id)) {
        array.push(m);
      }
    });

    return array;
  });

  const fetchEventRooms = async () => {
    const { data } = await getEventRooms(eventId);
    eventRooms.value = data;
  };

  const fetchEventGroups = async () => {
    const { data } = await getEventGroups(eventId);
    eventGroups.value = data;
  };

  const fetchInvitedUsers = async () => {
    const { data } = await getUserInvites('Event', eventId);
    userInvites.value = data;
  };

  const fetchInvites = async () => {
    const { data } = await getEventInvites(eventId);
    invites.value = data;

    addUsersCorrectlyToAllSectionsAndGroups();
  };

  const addUsersCorrectlyToAllSectionsAndGroups = () => {
    const checkToAddMemberToEntity = (entity, type, shifts) => {
      const shift = shifts.find((s) => s.via_id === entity.id && s.via_type === type);
      if (shift) {
        if (!entity.invited_members.some((m) => m.id === shift.member.id)) {
          entity.invited_members.push(shift.member);
        }
      }
    };
    const invitedMembers = [];
    invites.value.groups.forEach((inviteOfGroup) => {
      inviteOfGroup.invitable.invited_members.forEach((member) => {
        invitedMembers.push(member);
      });
      inviteOfGroup.invitable.invited_subgroups.forEach((subgroup) => {
        subgroup.invited_members.forEach((member) => {
          invitedMembers.push(member);
        });
      });
    });
    invites.value.festival_sections.forEach((inviteOfFestivalSection) => {
      inviteOfFestivalSection.invitable.invited_members.forEach((member) => {
        invitedMembers.push(member);
      });
    });
    const shifts = invitedMembers.flatMap((member) =>
      member.shifts.map((shift) => {
        return { ...shift, member: member };
      })
    );

    invites.value.groups.forEach((inviteOfGroup) => {
      checkToAddMemberToEntity(inviteOfGroup.invitable, 'App\\Group', shifts);
      inviteOfGroup.invitable.invited_subgroups.forEach((subgroup) => {
        checkToAddMemberToEntity(subgroup, 'App\\Group', shifts);
      });
    });
    invites.value.festival_sections.forEach((inviteOfFestivalSection) => {
      checkToAddMemberToEntity(inviteOfFestivalSection.invitable, 'App\\Models\\Festivals\\FestivalSection', shifts);
    });
  };

  const fetch = async (force = true) => {
    if (!force && loaded.value) return;
    loaded.value = true;
    loading.value = true;
    await fetchInvites();
    await fetchInvitedUsers();
    await fetchEventGroups();
    loading.value = false;
  };

  Echo.join(`On.Event.${eventId}`).listen('.members.updated', async () => {
    await fetch();
  });

  if (shouldProvide) {
    provide(eventMembersKey, {
      loading,
      loaded,
      fetch,
      eventGroups,
      userInvites,
      invites,
      acceptedInvitesOfGroups,
      pendingInvitesOfGroups,
      declinedInvitesOfGroups,
      acceptedInvitesOfUsers,
      pendingInvitesOfUsers,
      declinedInvitesOfUsers,
      acceptedInvitesOfFestivalSections,
      pendingInvitesOfFestivalSections,
      declinedInvitesOfFestivalSections,
      allMembers,
      allInvitesOfUsers,
      allInvitesOfGroups,

      fetchEventRooms,
      eventRooms,
    });
  }

  return {
    loading,
    loaded,
    fetch,
    eventGroups,
    userInvites,
    invites,
    acceptedInvitesOfGroups,
    pendingInvitesOfGroups,
    declinedInvitesOfGroups,
    acceptedInvitesOfUsers,
    pendingInvitesOfUsers,
    declinedInvitesOfUsers,
    acceptedInvitesOfFestivalSections,
    pendingInvitesOfFestivalSections,
    declinedInvitesOfFestivalSections,
    allMembers,
    allInvitesOfUsers,
    allInvitesOfGroups,

    fetchEventRooms,
    eventRooms,
  };
};
