import { IEventResource } from '@/types/event-resource';

export const getEventResources = async (eventId: number) => {
  return axios.get<IEventResource[]>(`/api/event-resources`, {
    params: {
      event_id: eventId,
    },
  });
};

type Data = {
  event_id: number;
  event_resource_type_id: number;
  name: string;
  phone: string | null;
  email: string | null;
  title: string | null;
  event_group_id: number | null;
  start?: string | null;
  end?: string | null;
  is_global: boolean;
};

export const postEventResource = async (data: Data) => {
  return axios.post<IEventResource>(`/api/event-resources`, {
    event_id: data.event_id,
    event_resource_type_id: data.event_resource_type_id,
    name: data.name,
    phone: data.phone,
    email: data.email,
    title: data.title,
    event_group_id: data.event_group_id,
    start: data.start,
    end: data.end,
    is_global: data.is_global,
  });
};

export const patchEventResource = async (
  id: number,
  data: Omit<Data, 'event_id' | 'event_resource_type_id' | 'start'>
) => {
  return axios.patch<IEventResource>(`/api/event-resources/${id}`, {
    name: data.name,
    phone: data.phone,
    email: data.email,
    title: data.title,
    event_group_id: data.event_group_id,
    start: data.start,
    end: data.end,
    is_global: data.is_global,
  });
};

export const destroyEventResource = async (id: number, isGlobal: boolean) => {
  return axios.delete<string>(`/api/event-resources/${id}`, {
    params: {
      is_global: isGlobal,
    },
  });
};

export const convertIntoUser = async (id: number, userId: number, isGlobal: boolean) => {
  return axios.post<string>(`/api/event-resources/${id}/convert`, {
    model_type: 'App\\User',
    model_id: userId,
    is_global: isGlobal,
  });
};
