<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { getRoute, openRoute } from '@/util/route';
import { Items } from '@/components/VList.vue';
import { useUserStore } from '@/store/UserStore';
import { useTheme } from '@/util/changeTheme';
import VButton from '@/components/Inputs/VButton.vue';
import { nextTick, ref } from 'vue';
import ProfileSettingModal from '@/components/Navbar/ProfileSettingModal/ProfileSettingModal.vue';

const userStore = useUserStore();

const settingsModalOpen = ref(false);
const { changeTheme } = useTheme();

const adminLinks = userStore.admin
  ? [
      { title: 'Dashboard', route: getRoute('admin.dashboard') },
      {
        title: 'Administrator',
        action: () => {
          return (window.location = '/administrator');
        },
      },
      { title: 'Groups', route: getRoute('admin.groups.index') },
      { title: 'Festivals', route: getRoute('admin.festivals.index') },
      { title: 'Events', route: getRoute('admin.events.index') },
      { title: 'User Activity', route: getRoute('admin.userActivity') },
      { title: 'Data Display', route: getRoute('admin.dataDisplay') },
      { title: 'Venues', route: getRoute('admin.venues.index') },
      { title: 'Audit log', route: getRoute('admin.audits') },
      {
        title: 'Event Resource Types',
        route: getRoute('admin.eventResourceTypes'),
      },
      { title: 'Templates', route: getRoute('admin.templates.index') },
      {
        title: 'Push Notifications',
        route: getRoute('admin.pushNotifications.index'),
      },
      { title: 'Reminders', route: getRoute('admin.reminders') },
      { title: 'Caches', route: getRoute('admin.caches.groups') },
      { title: 'Components', route: getRoute('components') },
      { title: 'Design', route: getRoute('admin.design') },
      { title: 'API & Integrations', route: getRoute('profile.api') },
    ]
  : [];

const dropDownList = () => {
  const array = [
    {
      title: 'User Settings',
      value: 1,
      preIcon: 'fa-cog fa-regular',
      postIcon: !usePage().props?.auth?.user?.phone?.length
        ? `fa-exclamation-circle fa-regular ${userStore.theme === 'base' ? 'text-pending' : 'text'}`
        : '',
      action: async (closeIt: () => void) => {
        settingsModalOpen.value = false;
        closeIt();
        await nextTick();
        settingsModalOpen.value = true;
      },
    },
  ] as Items;

  if (userStore.intro) {
    array.push({
      title: `Show Tutorial`,
      value: 6,
      preIcon: 'fa-play fa-regular',
      action: (closeIt: () => void) => {
        userStore.intro();
        closeIt();
      },
    });
  }

  array.push({
    title: `Switch to ${userStore.theme === 'light' ? 'Dark' : 'Light'} Mode`,
    value: 7,
    subListLeft: true,
    preIcon: userStore.theme === 'light' ? 'fa-moon  fa-regular' : 'fa-sun-bright  fa-regular',
    action: (closeIt: () => void) => {
      changeTheme();
    },
  });
  if (usePage().props.authenticated && usePage().props.auth.user?.is_admin) {
    array.push({
      title: 'Admin Pages',
      subListLeft: true,
      items: adminLinks.map((link) => {
        return {
          title: link.title,
          preIcon: 'fa-cog fa-regular',
          action: (closeIt: () => void) => {
            if (link.action) return link.action();
            openRoute(link.route);
            closeIt();
          },
        };
      }),
      preIcon: 'fa-cog fa-regular',
    });
  }

  array.push({
    type: 'divider',
  });

  array.push({
    title: 'Logout',
    value: 'Logout',
    preIcon: 'fa-sign-out fa-regular',
    action: (closeIt: () => void) => {
      openRoute('/logout');
      closeIt();
    },
  });

  return array.filter((i) => i !== null);
};

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <VDropdown
    v-bind="$attrs"
    close-on-click
    :items="dropDownList()"
    :with-flex-one="false">
    <template #click-area>
      <VButton
        icon="fa-user fa-regular"
        :title="$page.props.auth.user?.first_name" />
    </template>
  </VDropdown>

  <ProfileSettingModal
    v-if="settingsModalOpen"
    @closed="settingsModalOpen = false" />
</template>
